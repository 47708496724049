import IntakeHelper from '../Helpers/IntakeHelper/IntakeHelper.js';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Buttonlink from '../Buttonlink/Buttonlink.js';
import './Intakes.css';

const IntakeComponent = () => {

    const [intake, setIntake] = useState(null);
    let { id } = useParams();
    const [role, setRole] = useState(null);

    useEffect(() => {
        IntakeHelper.getIntake(id).then((data) => {
            setIntake(data);
        });
    }, [id]);

    useEffect(() => {
        const fetchUserData = async () => {
            setRole(localStorage.getItem('role'));
        }

        fetchUserData(); // Fetch once on load

    }, []);

    return (
        <div className="intake">
            {
            (role === null || role !== 'admin') ? 
            <>
            <h1>You are not authorized to view this page.</h1>  
            <Buttonlink text="Login" href="/login" />
            </> :
            <>
            <h1>Intake</h1>
            { intake && Object.keys(intake).sort().map(key => (
                    Array.isArray(intake[key]) ? 
                        (<div key={key}>
                            <strong>{key}</strong>:
                            <ul className="array-list">
                                {intake[key].map((item, index) => <li key={index}>{item}</li>)}
                            </ul>
                        </div>)
                    :
                        (<p key={key}><strong>{key}: </strong>{intake[key]}</p>)
                ))
            }
            </>
            }
        </div>
    )
}

export default IntakeComponent;