import './Intakes.css';
import IntakeHelper from '../Helpers/IntakeHelper/IntakeHelper.js';
import Buttonlink from '../Buttonlink/Buttonlink.js';
import { useEffect, useState } from 'react';
import { Parser } from '@json2csv/plainjs';
import { saveAs } from 'file-saver';

const IntakesComponent = () => {
    const [intakes, setIntakes] = useState([]);
    const [role, setRole] = useState(null);

    useEffect(() => {
        IntakeHelper.getAllIntakes().then((data) => {
            setIntakes(data);
        });
    }, []);

    useEffect(() => {
        const fetchUserData = async () => {
            setRole(localStorage.getItem('role'));
        }

        fetchUserData(); // Fetch once on load

    }, []);

    const downloadCSV = () => {
        const parser = new Parser();
        const csvData = parser.parse(intakes);
        const blob = new Blob([csvData], { type: 'text/csv' });
        saveAs(blob, 'intakes.csv');
    };

    return (
        <div className="intakes">
            {
            (role === null || role !== 'admin') ? 
            <>
            <h1>You are not authorized to view this page.</h1>  
            <Buttonlink text="Login" href="/login" />
            </> :
            <>
            <h1>Intakes</h1>
            <button onClick={downloadCSV}>Download CSV</button>
            <table>
                <thead>
                    <tr>
                        <th>Date of Intake</th>
                        <th className='hide-on-small'>Name (or ID if no name provided)</th>
                        <th className='hide-on-large'>Name</th>
                        <th className='hide-on-small'>Color</th>
                        <th className='hide-on-small'>Sex</th>
                        <th>Breed</th>
                        <th className='hide-on-small'>Costs ($)</th>
                        <th className='hide-on-small'>Mileage</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        intakes.map(intake => (
                            <tr key={intake.intakeid}>
                                <td>{intake.intakeDateTime ? intake.intakeDateTime.split('T')[0] : ""}</td>
                                <td><a href={`/intake/${intake.intakeid}`}>{intake.catName ? intake.catName : intake.intakeid}</a></td>
                                <td className='hide-on-small'>{intake.color}</td>
                                <td className='hide-on-small'>{intake.sex}</td>
                                <td>{intake.breed}</td>
                                <td className='hide-on-small'>{intake.intakeCosts}</td>
                                <td className='hide-on-small'>{intake.mileage}</td>   
                                <td><Buttonlink text="Edit" href={`/updateIntake/${intake.intakeid}`} /></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            </>
            }
        </div>
    )
}

export default IntakesComponent;