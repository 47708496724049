import './IntakeForm.css';
import Button from '../Button/Button.js';
import DropDownMenu from '../DropDownMenu/DropDownMenu.js';
import CheckboxGroup from '../CheckBoxGroup/CheckBoxGroup.js';
import DateTimeSelect from '../DateTimeSelect/DateTimeSelect.js';
import IntakeHelper from '../Helpers/IntakeHelper/IntakeHelper.js';
import Buttonlink from '../Buttonlink/Buttonlink.js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { speciesOptions, breedOptions, catTypes, colorOptions, intakeOptions, tabbyTypeOptions, estimatedAgeOptions, sexOptions, expectedWeightOptions, sizeOptions,vaccinationOptions,treatmentOptions, dietaryNeedsOptions,medicalNeedsOptions, behavioralEmotionalNeedsOptions,otherSpecialNeedsOptions, behavioralObservationsOptions } from './formOptions.js';

const IntakeFormComponent = () => {

    const navigate = useNavigate();

    const [catName, setCatName] = useState('');
    const [breed, setBreed] = useState('');
    const [type, setType] = useState('');
    const [color, setColor] = useState('');
    const [sex, setSex] = useState('');
    const [spayNeutered, setSpayNeutered] = useState('');
    const [intakeCosts, setIntakeCosts] = useState('0');
    const [mileage, setMileage] = useState('0');
    const [vaccinations, setVaccinations] = useState([]);
    const [treatments, setTreatments] = useState([]);
    const [dietaryNeeds, setDietaryNeeds] = useState([]);
    const [medicalNeeds, setMedicalNeeds] = useState([]);
    const [behavioralEmotionalNeeds, setBehavioralEmotionalNeeds] = useState([]);
    const [otherSpecialNeeds, setOtherSpecialNeeds] = useState([]);
    const [behavioralObservations, setBehavioralObservations] = useState([]);
    const [species, setSpecies] = useState('Cat');
    const [tabbyType, setTabbyType] = useState('');
    const [estimatedAge, setEstimatedAge] = useState('');
    const [expectedWeight, setExpectedWeight] = useState('0');
    const [size, setSize] = useState('');
    const [distinguishingFeature, setDistinguishingFeature] = useState('');
    const [isEarTipped, setIsEarTipped] = useState('');
    const [circumstancesOfIntake, setCircumstancesOfIntake] = useState('');
    const [locationFound, setLocationFound] = useState('');
    const [addressFound, setAddressFound] = useState('');
    const [personWhoNotified, setPersonWhoNotified] = useState('');
    const [personWhoNotifiedContactEmail, setPersonWhoNotifiedContactEmail] = useState('');
    const [personWhoNotifiedContactPhone, setPersonWhoNotifiedContactPhone] = useState('');
    const [personWhoNotifiedContactAddress, setPersonWhoNotifiedContactAddress] = useState('');
    const [medicalNotes, setMedicalNotes] = useState('');
    const [illnessesInjuries, setIllnessesInjuries] = useState('');
    const [initialAssessment, setInitialAssessment] = useState('');
    const [specialNeedsDetails, setSpecialNeedsDetails] = useState('');
    const [microchipId, setMicrochipId] = useState('');
    const [placementGoals, setPlacementGoals] = useState('');
    const [additionalComments, setAdditionalComments] = useState('');

    const [intakeDateTime, setIntakeDateTime] = useState('');
    const [createdAtTime, setCreatedAtTime] = useState('');
    const [username, setUsername] = useState(null);
    const [role, setRole] = useState(null);

    const handleDateTimeChange = (dateTime) => {
      setIntakeDateTime(dateTime);
      // console.log("Selected Date/Time:", dateTime);
    };

    useEffect(() => {
      const fetchUserData = async () => {
          setRole(localStorage.getItem('role'));
      }

      fetchUserData(); // Fetch once on load

  }, []);
    
    useEffect(() => {
        const currentDateTime = moment().tz('America/New_York').format('YYYY-MM-DDTHH:mm');
        setCreatedAtTime(currentDateTime);

        const user = JSON.parse(localStorage.getItem('user'));

        if (user) { // checking if user is not null
            setUsername(user.email ? user.email : user.name);
        } else {
            setUsername("Unknown User"); // Set default username here
        }
    }, []);

    const handleSubmit = async (event) => {
      event.preventDefault();

      const items = {
          catName,
          breed,
          type,
          color,
          sex,
          spayNeutered,
          intakeCosts,
          mileage,
          vaccinations,
          treatments,
          dietaryNeeds,
          medicalNeeds,
          behavioralEmotionalNeeds,
          otherSpecialNeeds,
          behavioralObservations,
          species,
          tabbyType,
          estimatedAge,
          expectedWeight,
          size,
          distinguishingFeature,
          isEarTipped,
          circumstancesOfIntake,
          locationFound,
          addressFound,
          personWhoNotified,
          personWhoNotifiedContactEmail,
          personWhoNotifiedContactPhone,
          personWhoNotifiedContactAddress,
          medicalNotes,
          illnessesInjuries,
          initialAssessment,
          specialNeedsDetails,
          microchipId,
          placementGoals,
          additionalComments,
          intakeDateTime,
          createdAtTime,
          username,
      };

      try {
        // const response = await IntakeHelper.postIntake(items);
        await IntakeHelper.postIntake(items);
        toast.success('Intake added successfully!');
        navigate('/intakes');
        // console.log(response)
      } catch (error) {
          toast.error('Error adding intake. Please try again.');
          // console.log('Error posting intake data:', error);
      }

      // navigate('/intakes');
      // window.location.reload();
  };

  return (
    <div className="IntakeForm">
      {
            (role === null || role !== 'admin') ? 
            <>
            <h1>You are not authorized to view this page.</h1>  
            <Buttonlink text="Login" href="/login" />
            </> :
            <>
      <form onSubmit={handleSubmit}>
        <div>
          <h3><a href="/intakes">See all intakes</a></h3>
          <br/>
          <DateTimeSelect onDateTimeChange={handleDateTimeChange} />
          <br />
          <label>Cat Name:</label><input type="text" onChange={(e) => setCatName(e.target.value)} id="catName"/>
          <div className="note">
            <span>*NOTE: This is an optional field for reference only. The name can be changed later</span>
          </div>
          <br/>
          <h3>Required Information:</h3>
          <DropDownMenu options={breedOptions} label="Breed" onSelectedChange={(choice) => { setBreed(choice);}} required={true} id="breed" />
          <DropDownMenu options={catTypes} label="Type" onSelectedChange={(choice) => { setType(choice);}} required={true} id="catType" />
          <DropDownMenu options={colorOptions} label="Color" onSelectedChange={(choice) => { setColor(choice);}} required={true} id="color" />
          <DropDownMenu options={sexOptions} label="Sex" onSelectedChange={(choice) => { setSex(choice);}} id="sex" />
          <DropDownMenu options={["", "Unknown", "Yes", "No"]} label="Spay/Neutered?" onSelectedChange={(choice) => { setSpayNeutered(choice);}} required={true} id="isSpayed" />
          <label>Intake Costs:</label><input type="number" min='0' defaultValue={0} step="1.00" onChange={(e) => setIntakeCosts(e.target.value)} id="intakeCosts" required /><br/>
          <div className="RequiredNumber">
            <span>*Initial Cost is required.</span>
          </div>
          <label>Mileage:</label><input type="number" min='0' defaultValue={0} onChange={(e) => setMileage(e.target.value)} id="mileage" required /><br/>
          <div className="RequiredNumber">
            <span>*Mileage is required.</span>
          </div>
        </div>
        <br></br>
        <hr/>
        <br></br>
        <h3>Animal Information:</h3>
        <DropDownMenu options={speciesOptions} label="Species" onSelectedChange={(choice) => { setSpecies(choice);}} defaultValue="Cat" id="species" />
        <DropDownMenu options={tabbyTypeOptions} label="Tabby Type (if applicable)" onSelectedChange={(choice) => { setTabbyType(choice);}} id="tabbyType" required={false} />
        <DropDownMenu options={estimatedAgeOptions} label="Estimated Age" onSelectedChange={(choice) => { setEstimatedAge(choice);}} id="estimatedAge" required={false} />
        <DropDownMenu options={expectedWeightOptions} label="Expected Weight" onSelectedChange={(choice) => { setExpectedWeight(choice);}} id="expectedWeight" required={false} />
        <DropDownMenu options={sizeOptions} label="Size" onSelectedChange={(choice) => { setSize(choice);}} id="size" required={false} />
        <label>Distinguishing Feature:</label><input type="text" onChange={(e) => setDistinguishingFeature(e.target.value)} id="distinguisingFeature" /><br/>
        {/* <RadioButtonGroup label="Ear Tipped?" /> */}
        <DropDownMenu options={["", "Yes", "No"]} label="Ear Tipped?" onSelectedChange={(choice) => { setIsEarTipped(choice);}} id="isEarTipped" required={false} />
        <br />
        <hr/>
        <br></br>
        <h3>Circumstances of Intake</h3>
        <DropDownMenu options={intakeOptions} label="Found, Surrendered, Transfer" onSelectedChange={(choice) => { setCircumstancesOfIntake(choice);}} id="circumstancesOfIntake" required={false} />
        <br />
        <h5>Animal's Initial Location</h5>
        { /* Try USPS address verifier; Try Google Maps */}
        <label>General Location Found:</label><input type="text" onChange={(e) => setLocationFound(e.target.value)} id="locationFound" /><br/>
        { /* Try USPS address verifier; Try Google Maps */}
        <label>Or Specific Address:</label><input type="text" onChange={(e) => setAddressFound(e.target.value)} id="addressFound" /><br/>
        <br/>
        <hr/>
        <br></br>
        <h3>Finder/Relinquisher Information:</h3>
        <label>Name of person who alerted you to cat:</label><input type="text" onChange={(e) => setPersonWhoNotified(e.target.value)} id="personWhoNotified"/><br/>
        <h5>Contact info:</h5>
        <label>Email:</label><input type="text" onChange={(e) => setPersonWhoNotifiedContactEmail(e.target.value)} id="personWhoNotifiedContactEmail" /><br/>
        <label>Phone Number:</label><input type="text" onChange={(e) => setPersonWhoNotifiedContactPhone(e.target.value)} id="personWhoNotifiedContactPhone"/><br/>
        <label>Address:</label><input type="text" onChange={(e) => setPersonWhoNotifiedContactAddress(e.target.value)} id="personWhoNotifiedContactAddress"/><br/>
        <br />
        <hr/>
        <br></br>
        <h3>Medical and Behavioral Information:</h3>
        <h5>Medical History</h5>
        <CheckboxGroup
          label="Vaccinations:"
          options={vaccinationOptions}
          onOptionsChange={(selectedOptions) => { 
            setVaccinations(selectedOptions);
          }}
        />
        <CheckboxGroup 
          label="Treatments:" 
          options={treatmentOptions} 
          onOptionsChange={(selectedOptions) => { 
            setTreatments(selectedOptions); 
            }} 
        /> 
        <label>Additional Medical Notes:</label><textarea onChange={(e) => setMedicalNotes(e.target.value)} id="medicalNotes"/><br/>
        <label>Illnesses or Injuries:</label><textarea onChange={(e) => setIllnessesInjuries(e.target.value)} id="illnessesInjuries"/><br/>
        <h5>Behavioral Observations</h5>
        <CheckboxGroup options={behavioralObservationsOptions} label="Behavioral Observations:" onOptionsChange={(selectedOptions) => { setBehavioralObservations(selectedOptions); }} required={false} />
        <label>Initial Assessment:</label><textarea onChange={(e) => setInitialAssessment(e.target.value)} id="initialAssessment"/><br/>
        <br />
        <hr/>
        <br></br>
        <h3>Special Needs:</h3>
        <CheckboxGroup label="Dietary Needs:" options={dietaryNeedsOptions} onOptionsChange={(selectedOptions) => { setDietaryNeeds(selectedOptions); }} required={false} />
        <CheckboxGroup options={medicalNeedsOptions} label="Medical Needs:" onOptionsChange={(selectedOptions) => { setMedicalNeeds(selectedOptions); }} required={false} />
        <CheckboxGroup options={behavioralEmotionalNeedsOptions} label="Behavioral / Emotional Needs:" onOptionsChange={(selectedOptions) => { setBehavioralEmotionalNeeds(selectedOptions); }} required={false} />
        <CheckboxGroup options={otherSpecialNeedsOptions} label="Other Special Needs:" onOptionsChange={(selectedOptions) => { setOtherSpecialNeeds(selectedOptions); }} required={false} />
        <label>Special Needs Details:</label><textarea onChange={(e) => setSpecialNeedsDetails(e.target.value)} id="specialNeedsDetails" /><br/>
        <br />
        <hr/>
        <br></br>
        <h3>Additional Information:</h3>
        <label>Microchip ID:</label><input type="text" onChange={(e) => setMicrochipId(e.target.value)} id="microchipId" /><br/>
        <label>Placement Goals:</label><textarea onChange={(e) => setPlacementGoals(e.target.value)} id="placementGoals"/><br/>
        
        <label>Additional Comments:</label><textarea onChange={(e) => setAdditionalComments(e.target.value)} id="additionalComments" /><br/>
        <br />
        <Button buttonText='Submit' color='grey' />      
        </form>
        </>
        }
    </div>
  );
}

export default IntakeFormComponent;