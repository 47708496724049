import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment-timezone';
import './DateTimeSelect.css';

const DateTimeSelect = ({ onDateTimeChange }) => {

  const getCurrentDateTime = useCallback(() => {
    const currentDateTime = moment().tz('America/New_York').format('YYYY-MM-DDTHH:mm');
    return currentDateTime;
  }, []);

  const [selectedDateTime, setSelectedDateTime] = useState(getCurrentDateTime());
  const [isInitialized, setIsInitialized] = useState(false);

  // Set initial value without triggering onChange
  useEffect(() => {
    if (!isInitialized) {
      onDateTimeChange(getCurrentDateTime());
      setIsInitialized(true);
    }
  }, [onDateTimeChange, getCurrentDateTime, isInitialized]);

  const handleDateChange = (event) => {
    const newDateTime = event.target.value;
    setSelectedDateTime(newDateTime);
    if (isInitialized) {
      onDateTimeChange(newDateTime);  // Use newDateTime directy from the event
    }
  };

  return (
    <div>
      <label>Date/Time of Intake:</label>
      <input 
        type="datetime-local" 
        value={selectedDateTime} 
        onChange={handleDateChange} 
        required
      />
    </div>
  );
}

export default DateTimeSelect;

