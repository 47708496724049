import '../IntakeForm/IntakeForm.css'
import Button from '../Button/Button.js';
import DropDownMenu from '../DropDownMenu/DropDownMenu.js';
import CheckboxGroup from '../CheckBoxGroup/CheckBoxGroup.js';
import IntakeHelper from '../Helpers/IntakeHelper/IntakeHelper.js';
import Buttonlink from '../Buttonlink/Buttonlink.js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { speciesOptions, breedOptions, catTypes, colorOptions, intakeOptions, tabbyTypeOptions, estimatedAgeOptions, sexOptions, expectedWeightOptions, sizeOptions,vaccinationOptions,treatmentOptions, dietaryNeedsOptions,medicalNeedsOptions, behavioralEmotionalNeedsOptions,otherSpecialNeedsOptions, behavioralObservationsOptions } from '../IntakeForm/formOptions.js';
// import { set } from 'date-fns';

const UpdateIntakeComponent = () => {
    const navigate = useNavigate();

    const [intake, setIntake] = useState(null);
    let { id } = useParams();

    const [catName, setCatName] = useState('');
    const [breed, setBreed] = useState('');
    const [type, setType] = useState('');
    const [color, setColor] = useState('');
    const [sex, setSex] = useState('');
    const [spayNeutered, setSpayNeutered] = useState('');
    const [intakeCosts, setIntakeCosts] = useState('0');
    const [mileage, setMileage] = useState('0');
    const [vaccinations, setVaccinations] = useState([]);
    const [treatments, setTreatments] = useState([]);
    const [dietaryNeeds, setDietaryNeeds] = useState([]);
    const [medicalNeeds, setMedicalNeeds] = useState([]);
    const [behavioralEmotionalNeeds, setBehavioralEmotionalNeeds] = useState([]);
    const [otherSpecialNeeds, setOtherSpecialNeeds] = useState([]);
    const [behavioralObservations, setBehavioralObservations] = useState([]);
    const [species, setSpecies] = useState('Cat');
    const [tabbyType, setTabbyType] = useState('');
    const [estimatedAge, setEstimatedAge] = useState('');
    const [expectedWeight, setExpectedWeight] = useState('0');
    const [size, setSize] = useState('');
    const [distinguishingFeature, setDistinguishingFeature] = useState('');
    const [isEarTipped, setIsEarTipped] = useState('');
    const [circumstancesOfIntake, setCircumstancesOfIntake] = useState('');
    const [locationFound, setLocationFound] = useState('');
    const [addressFound, setAddressFound] = useState('');
    const [personWhoNotified, setPersonWhoNotified] = useState('');
    const [personWhoNotifiedContactEmail, setPersonWhoNotifiedContactEmail] = useState('');
    const [personWhoNotifiedContactPhone, setPersonWhoNotifiedContactPhone] = useState('');
    const [personWhoNotifiedContactAddress, setPersonWhoNotifiedContactAddress] = useState('');
    const [medicalNotes, setMedicalNotes] = useState('');
    const [illnessesInjuries, setIllnessesInjuries] = useState('');
    const [initialAssessment, setInitialAssessment] = useState('');
    const [specialNeedsDetails, setSpecialNeedsDetails] = useState('');
    const [microchipId, setMicrochipId] = useState('');
    const [placementGoals, setPlacementGoals] = useState('');
    const [additionalComments, setAdditionalComments] = useState('');
    const [createdAtTime, setCreatedAtTime] = useState('');

    const [updatedTime, setUpdatedTime] = useState('');
    const [username, setUsername] = useState(null);
    const [role, setRole] = useState(null);

    useEffect(() => {
        IntakeHelper.getIntake(id).then((data) => {
            setIntake(data);
            setCatName(data.catName || '');
            setIntakeCosts(data.intakeCosts || '0');
            setMileage(data.mileage || '0');
            setLocationFound(data.locationFound || '');
            setDistinguishingFeature(data.distinguishingFeature || '');
            setAddressFound(data.addressFound || '');
            setPersonWhoNotified(data.personWhoNotified || '');
            setPersonWhoNotifiedContactEmail(data.personWhoNotifiedContactEmail || '');
            setPersonWhoNotifiedContactPhone(data.personWhoNotifiedContactPhone || '');
            setPersonWhoNotifiedContactAddress(data.personWhoNotifiedContactAddress || '');
            setMedicalNotes(data.medicalNotes || '');
            setIllnessesInjuries(data.illnessesInjuries || '');
            setInitialAssessment(data.initialAssessment || '');
            setSpecialNeedsDetails(data.specialNeedsDetails || '');
            setMicrochipId(data.microchipId || '');
            setPlacementGoals(data.placementGoals || '');
            setAdditionalComments(data.additionalComments || '');
            setCreatedAtTime(data.createdAtTime || '');
            setUpdatedTime(data.updatedTime || '');
            setUsername(data.username || '');
        });
    }, [id]);

    useEffect(() => {
      const fetchUserData = async () => {
          setRole(localStorage.getItem('role'));
      }

      fetchUserData(); // Fetch once on load

  }, []);
    
    const handleSubmit = async (event) => {
      event.preventDefault();

      const currentDateTime = moment().tz('America/New_York').format('YYYY-MM-DDTHH:mm');

      const user = JSON.parse(localStorage.getItem('user'));

      const items = {
          catName,
          breed,
          type,
          color,
          sex,
          spayNeutered,
          intakeCosts,
          mileage,
          vaccinations,
          treatments,
          dietaryNeeds,
          medicalNeeds,
          behavioralEmotionalNeeds,
          otherSpecialNeeds,
          behavioralObservations,
          species,
          tabbyType,
          estimatedAge,
          expectedWeight,
          size,
          distinguishingFeature,
          isEarTipped,
          circumstancesOfIntake,
          locationFound,
          addressFound,
          personWhoNotified,
          personWhoNotifiedContactEmail,
          personWhoNotifiedContactPhone,
          personWhoNotifiedContactAddress,
          medicalNotes,
          illnessesInjuries,
          initialAssessment,
          specialNeedsDetails,
          microchipId,
          placementGoals,
          additionalComments,
          updatedTime: currentDateTime,
          username: user.email,
      };

      try {
        // const response = await IntakeHelper.putIntake(id, items);
        await IntakeHelper.putIntake(id, items);
        toast.success('Intake updated successfully!');
        navigate('/intakes');
        // console.log(response)
      } catch (error) {
        toast.error('Error updating intake. Please try again.');
        // console.log('Error posting intake data:', error);
      }

    //   navigate('/intakes');
    //   window.location.reload();
  };

  return (
    <div className="IntakeForm">
      {
            (role === null || role !== 'admin') ? 
            <>
            <h1>You are not authorized to view this page.</h1>  
            <Buttonlink text="Login" href="/login" />
            </> :
            <>
      <form onSubmit={handleSubmit}>
        <div>
            <div>
                <h4>Intake Date/Time:</h4> {intake?.intakeDateTime || ''} 
            </div>
            <div>
                <h4>Last Updated:</h4> {updatedTime || createdAtTime}
                <h4>Updated By:</h4> {username || ''}
            </div>
            <br />
            <label>Cat Name:</label><input type="text" value={catName} onChange={(e) => setCatName(e.target.value)} id="catName"/>          
          <br/>
          <h3>Required Information:</h3>
          <DropDownMenu options={breedOptions} label="Breed" defaultValue={intake?.breed || ''} onSelectedChange={(choice) => { setBreed(choice);}} required={true} id="breed" />
          <DropDownMenu options={catTypes} label="Type" defaultValue={intake?.type || ''} onSelectedChange={(choice) => { setType(choice);}} required={true} id="catType" />
          <DropDownMenu options={colorOptions} label="Color" defaultValue={intake?.color || ''} onSelectedChange={(choice) => { setColor(choice);}} required={true} id="color" />
          <DropDownMenu options={sexOptions} label="Sex" defaultValue={intake?.sex || ''} onSelectedChange={(choice) => { setSex(choice);}} id="sex" />
          <DropDownMenu options={["", "Unknown", "Yes", "No"]} label="Spay/Neutered?" defaultValue={intake?.spayNeutered || ''} onSelectedChange={(choice) => { setSpayNeutered(choice);}} required={true} id="isSpayed" />
          <label>Intake Costs:</label>
          <input type="number" min='0' value={intakeCosts} step="1.00" onChange={(e) => setIntakeCosts(e.target.value)} id="intakeCosts" /><br/>
          <label>Mileage:</label>
          <input type="number" min='0' value={mileage} onChange={(e) => setMileage(e.target.value)} id="mileage" /><br/>
        </div>
        <br></br>
        <hr/>
        <br></br>
        <h3>Animal Information:</h3>
        <DropDownMenu options={speciesOptions} label="Species" defaultValue={intake?.species || 'Cat'} onSelectedChange={(choice) => { setSpecies(choice);}} id="species" />
        <DropDownMenu options={tabbyTypeOptions} label="Tabby Type (if applicable)" defaultValue={intake?.tabbyType || ''} onSelectedChange={(choice) => { setTabbyType(choice);}} id="tabbyType" required={false} />
        <DropDownMenu options={estimatedAgeOptions} label="Estimated Age" defaultValue={intake?.estimatedAge || ''} onSelectedChange={(choice) => { setEstimatedAge(choice);}} id="estimatedAge" required={false} />
        <DropDownMenu options={expectedWeightOptions} label="Expected Weight" defaultValue={intake?.expectedWeight || ''} onSelectedChange={(choice) => { setExpectedWeight(choice);}} id="expectedWeight" required={false} />
        <DropDownMenu options={sizeOptions} label="Size" defaultValue={intake?.size || ''} onSelectedChange={(choice) => { setSize(choice);}} id="size" required={false} />
        <label>Distinguishing Feature:</label><input type="text" value={distinguishingFeature} onChange={(e) => setDistinguishingFeature(e.target.value)} id="distinguisingFeature" /><br/>
        {/* <RadioButtonGroup label="Ear Tipped?" /> */}
        <DropDownMenu options={["", "Yes", "No"]} label="Ear Tipped?" defaultValue={intake?.isEarTipped || ''} onSelectedChange={(choice) => { setIsEarTipped(choice);}} id="isEarTipped" required={false} />
        <br />
        <hr/>
        <br></br>
        <h3>Circumstances of Intake</h3>
        <DropDownMenu options={intakeOptions} label="Found, Surrendered, Transfer" defaultValue={intake?.circumstancesOfIntake || ''} onSelectedChange={(choice) => { setCircumstancesOfIntake(choice);}} id="circumstancesOfIntake" required={false} />
        <br />
        <h5>Animal's Initial Location</h5>
        <label>General Location Found:</label>
        <input type="text" value={locationFound} onChange={(e) => setLocationFound(e.target.value)} id="locationFound" /><br/>
        <label>Or Specific Address:</label>
        <input type="text" value={addressFound} onChange={(e) => setAddressFound(e.target.value)} id="addressFound" /><br/>
        <br/>
        <hr/>
        <br></br>
        <h3>Finder/Relinquisher Information:</h3>
        <label>Name of person who alerted you to cat:</label>
        <input type="text" value={personWhoNotified} onChange={(e) => setPersonWhoNotified(e.target.value)} id="personWhoNotified"/><br/>
        <h5>Contact info:</h5>
        <label>Email:</label>
        <input type="text" value={personWhoNotifiedContactEmail} onChange={(e) => setPersonWhoNotifiedContactEmail(e.target.value)} id="personWhoNotifiedContactEmail" /><br/>
        <label>Phone Number:</label>
        <input type="text" value={personWhoNotifiedContactPhone} onChange={(e) => setPersonWhoNotifiedContactPhone(e.target.value)} id="personWhoNotifiedContactPhone"/><br/>
        <label>Address:</label>
        <input type="text" value={personWhoNotifiedContactAddress} onChange={(e) => setPersonWhoNotifiedContactAddress(e.target.value)} id="personWhoNotifiedContactAddress"/><br/>
        <br />
        <hr/>
        <br></br>
        <h3>Medical and Behavioral Information:</h3>
        <h5>Medical History</h5>
        <CheckboxGroup
          label="Vaccinations:"
          options={vaccinationOptions}
          defaultValues={intake?.vaccinations || ''}
          onOptionsChange={(selectedOptions) => { 
            setVaccinations(selectedOptions);
          }}
        />
        <CheckboxGroup 
          label="Treatments:" 
          options={treatmentOptions} 
          defaultValues={intake?.treatments || ''}
          onOptionsChange={(selectedOptions) => { 
            setTreatments(selectedOptions); 
            }} 
        /> 
        <label>Additional Medical Notes:</label>
        <textarea value={medicalNotes} onChange={(e) => setMedicalNotes(e.target.value)} id="medicalNotes"/><br/>
        <label>Illnesses or Injuries:</label>
        <textarea value={illnessesInjuries} onChange={(e) => setIllnessesInjuries(e.target.value)} id="illnessesInjuries"/><br/>
        <h5>Behavioral Observations</h5>
        <CheckboxGroup defaultValues={intake?.behavioralObservations || ''} options={behavioralObservationsOptions} label="Behavioral Observations:" onOptionsChange={(selectedOptions) => { setBehavioralObservations(selectedOptions); }} required={false} />
        <label>Initial Assessment:</label>
        <textarea value={initialAssessment} onChange={(e) => setInitialAssessment(e.target.value)} id="initialAssessment"/><br/>
        <br />
        <hr/>
        <br></br>
        <h3>Special Needs:</h3>
        <CheckboxGroup label="Dietary Needs:" defaultValues={intake?.dietaryNeeds || ''} options={dietaryNeedsOptions} onOptionsChange={(selectedOptions) => { setDietaryNeeds(selectedOptions); }} required={false} />
        <CheckboxGroup defaultValues={intake?.medicalNeeds || ''} options={medicalNeedsOptions} label="Medical Needs:" onOptionsChange={(selectedOptions) => { setMedicalNeeds(selectedOptions); }} required={false} />
        <CheckboxGroup defaultValues={intake?.behavioralEmotionalNeeds || ''} options={behavioralEmotionalNeedsOptions} label="Behavioral / Emotional Needs:" onOptionsChange={(selectedOptions) => { setBehavioralEmotionalNeeds(selectedOptions); }} required={false} />
        <CheckboxGroup defaultValues={intake?.otherSpecialNeeds || ''} options={otherSpecialNeedsOptions} label="Other Special Needs:" onOptionsChange={(selectedOptions) => { setOtherSpecialNeeds(selectedOptions); }} required={false} />
        <label>Special Needs Details:</label>
        <textarea value={specialNeedsDetails} onChange={(e) => setSpecialNeedsDetails(e.target.value)} id="specialNeedsDetails" /><br/>
        <br />
        <hr/>
        <br></br>
        <h3>Additional Information:</h3>
        <label>Microchip ID:</label>
        <input type="text" value={microchipId} onChange={(e) => setMicrochipId(e.target.value)} id="microchipId" /><br/>
        <label>Placement Goals:</label>
        <textarea value={placementGoals} onChange={(e) => setPlacementGoals(e.target.value)} id="placementGoals"/><br/>
        
        <label>Additional Comments:</label>
        <textarea value={additionalComments} onChange={(e) => setAdditionalComments(e.target.value)} id="additionalComments" /><br/>
        <br />
        <Button buttonText='Submit' color='grey' />      
        </form>
        </>
        }
    </div>
  );
}

export default UpdateIntakeComponent;