import { useAuth } from "react-oidc-context";

function LoginCognito() {
  const auth = useAuth();

  // const signOutRedirect = () => {
  //   const clientId = "6cmmm5ra4pk8r8lveef69qvp03";
  //   const logoutUri = "https://localhost:3000";
  //   const cognitoDomain = "https://us-east-1vvddyxb5y.auth.us-east-1.amazoncognito.com";
  //   window.location.href = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${encodeURIComponent(logoutUri)}`;
  // };

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Encountering error... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    console.log(auth.user);
    localStorage.setItem('user', JSON.stringify(auth.user?.profile));
    return (
      <div>
        <pre> Hello: {auth.user?.profile.email} </pre>
        <pre> ID Token: {auth.user?.id_token} </pre>
        <pre> Access Token: {auth.user?.access_token} </pre>
        <pre> Refresh Token: {auth.user?.refresh_token} </pre>

        {/* <button onClick={() => auth.removeUser()}>Sign out</button> */}
      </div>
    );
  }

  return (
    <div>
      <button onClick={() => auth.signinRedirect({ target: '_self' })}>Sign in</button>
      {/* <button onClick={() => signOutRedirect()}>Sign out</button> */}
    </div>
  );
}
  
export default LoginCognito;