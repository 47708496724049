import React, { useState, useEffect } from 'react';

const CheckBoxGroup = ({ label, options, onOptionsChange, defaultValues }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOtherChecked, setIsOtherChecked] = useState(
    options.reduce((acc, option) => ({ ...acc, [option]: false }), {})
  );
  

  useEffect(() => {
    onOptionsChange(selectedOptions);
  }, [selectedOptions, onOptionsChange]);

  const handleOptionChange = (event) => {
    const optionValue = event.target.value;
    setIsOtherChecked(prevState => ({
      ...prevState,
      [optionValue]: !prevState[optionValue],
    }));
  
    setSelectedOptions(prevState => {
      if (prevState.some(opt => opt.startsWith(optionValue))) {
        return prevState.filter(opt => !opt.startsWith(optionValue));
      } else {
        return [...prevState, optionValue.includes('please specify') ? `${optionValue}: ` : optionValue];
      }
    });
  };

    useEffect(() => {
      if (defaultValues && defaultValues.length > 0) {
        console.log("defaultValues:", defaultValues);
        let newIsOtherChecked = options.reduce((acc, option) => {
          return { 
            ...acc, 
            [option]: defaultValues.some(val => val.startsWith(option))
          };
        }, {});
        console.log("newIsOtherChecked:", newIsOtherChecked);
        setSelectedOptions(defaultValues);
        setIsOtherChecked(newIsOtherChecked);
      }
    }, [defaultValues, options]);
  

  const handleTextInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedOptions(prevState => {
      const newState = prevState.filter(option => !option.startsWith(name));
      return [...newState, `${name}: ${value}`];
    });
  };
  
  

  return (
    <div>
        <label>{label}</label>
        <div className="checkboxGrid">
        {options.map((option, index) => (
          <div key={index} className="checkboxes">
            <input
              type="checkbox"
              id={option}
              name={option}
              value={option}
              checked={isOtherChecked[option]}
              onChange={handleOptionChange}
            />
            <label htmlFor={option}>{option}</label>
            {option.includes("please specify") && isOtherChecked[option] && (
              <input
                type="text"
                name={option}
                placeholder={`Specify ${option}`}
                value={selectedOptions.find(opt => opt.startsWith(option))?.split(': ')[1] || ''}
                onChange={handleTextInputChange}
              />
            )}
          </div>
        ))}
          </div>
        </div>
  );
};

export default CheckBoxGroup;