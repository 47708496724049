import { useEffect, useState } from 'react';  // Import `useState` hook
import './UserAccount.css'
import Linknav from '../Linknav/Linknav';
import Buttonlink from '../Buttonlink/Buttonlink';
import { useAuth } from "react-oidc-context";

function UserAccount() {

  const auth = useAuth();

  const [userEmail, setUserEmail] = useState('');  // Initialize state

  useEffect(() => {
    if (auth.isAuthenticated) {
      console.log(auth.user);
      localStorage.setItem('user', JSON.stringify(auth.user?.profile));
      const userData = JSON.parse(localStorage.getItem('user')); // Parsing the string into JavaScript object
      setUserEmail(userData.email);
    }
  }, [auth]);

  return (
    <>
    <div className='user-account-bgimg'>
      <div id="user-account" className='user-account-div'>
        <br></br>
        <br></br>
        <br></br>
        <h1>Thank you for being a part of 12inchtails!</h1>
        <p>Your email is: {userEmail}</p> {/* Displaying the user email */}
        <div>
            {/* <p><a href="/logout">Logout</a></p> */}
            <p>
                <Buttonlink text="Logout" href="/logout"/>
            </p>
        </div>
        <br></br>
        <br></br>
        <br></br>
          <Linknav 
            links={[
              { href: "/waystogive", text: "Donate" },
              { href: "/foster", text: "Foster" },
              { href: "/adopt", text: "Adopt" },
            ]}
          />
        </div>
      </div>
    </>
  )
}

export default UserAccount;