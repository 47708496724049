import './Intakes.css'

const DeleteIntakeComponent = () => {
    return (
        <div className="delete-intake">
            <h1>Delete Intake</h1>
        </div>
    )
}

export default DeleteIntakeComponent;