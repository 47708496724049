import { BrowserRouter, Routes, Route } from "react-router-dom";
import { React, useEffect } from 'react';
import IntakeForm from './components/IntakeForm/IntakeForm.js';
import Login from './components/Login/Login.js';
import Home from './components/Home/Home.js';
import Navbar from "./components/Navbar/Navbar.js";
import Donate from "./components/Donate/Donate.js";
import Faq from "./components/Faq/Faq.js";
import Footer from "./components/Footer/Footer.js";
import Contact from "./components/Contact/Contact.js";
import Services from "./components/Services/Services.js";
import About from "./components/About/About.js";
import Waystogive from "./components/Waystogive/Waystogive.js";
import Followus from "./components/Followus/Followus.js";
import Socialmedia from "./components/Socailmedia/Socialmedia.js";
import Adopt from "./components/Adopt/Adopt.js";
import Foster from "./components/Foster/Foster.js";
import Volunteer from "./components/Volunteer/Volunteer.js";
import Shop from "./components/Shop/Shop.js";
import Logout from "./components/Logout/Logout.js";
import Welcome from "./components/Welcome/Welcome.js";
import UserAccount from "./components/UserAccount/UserAccount.js";
import Intakes from "./components/Intakes/Intakes.js";
import Intake from "./components/Intakes/Intake.js";
import UpdateIntake from "./components/Intakes/UpdateIntake.js";
import DeleteIntake from "./components/Intakes/DeleteIntake.js";
import Petfinder from "./components/Petfinder/Petfinder.js";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import './App.css';
import { useAuth } from "react-oidc-context";

function App() {

  const auth = useAuth();

  let userProfile = null;

  if (auth.user && auth.user.profile) {
    userProfile = JSON.stringify(auth.user.profile);
    console.log(userProfile);
  } else {
    console.log('User profile is null');
  }

  useEffect(() => {
      if (!document.querySelector(`script[src='https://www.petfinder.com/assets/widgets/scripts/main-widgets-web.js']`)) {
          const scriptTag = document.createElement('script');
          scriptTag.src = 'https://www.petfinder.com/assets/widgets/scripts/main-widgets-web.js';
          document.body.appendChild(scriptTag);
      }
  }, []);

  return (
    <HelmetProvider>
    <div className="App">
      <Helmet>
        <meta name="description" 
        content="Twelve Inch Tails Animal Rescue, deeply devoted to the well-being of homeless and needy animals, lovingly serves the communities of South Jersey, with a special focus on Atlantic City, Absecon, Galloway Township, Egg Harbor City, and Egg Harbor Township.

        Our heart lies in rescuing and rehoming cats, addressing their unique needs. We offer adoption and fostering services as well as TNR (Trap, Neuter, Return) and TNA (Trap, Neuter, Assess). We are deeply invested in the individual well-being and future of each animal. We dedicate ourselves to helping adoptable cats and kittens transition from the challenging life on the streets to the warm and loving embrace of a forever home." />
        <title>12 Inch Tails</title>
      </Helmet>
      {/* <header></header> */}
      <Navbar userProfile={userProfile} />
      {/* <Navbar /> */}
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/intake" element={<IntakeForm/>} />
          <Route path="/home" element={<Home/>} />
          <Route path="/waystogive" element={<Waystogive/>} />
          <Route path="/followus" element={<Followus/>} />
          <Route path="/donate" element={<Donate/>} />
          <Route path="/faq" element={<Faq/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/services" element={<Services/>} />
          <Route path="/adopt" element={<Adopt/>} />
          <Route path="/foster" element={<Foster/>} />
          <Route path="/volunteer" element={<Volunteer/>} />
          <Route path="/shop" element={<Shop/>} />
          {/* <Route path="/wishlist" element={<Wishlist/>} /> */}
          <Route path="/about" element={<About/>} />
          <Route path="/welcome" element={<Welcome/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/logout" element={<Logout/>} />
          <Route path="/userAccount" element={<UserAccount/>} />
          <Route path="/intakes" element={<Intakes/>} />
          <Route path="/intake/:id" element={<Intake/>} />
          <Route path="/updateIntake/:id" element={<UpdateIntake/>} />
          <Route path="/deleteIntake/:id" element={<DeleteIntake/>} />
          <Route path="/petfinder" element={<Petfinder />} />
          {/* <Route path="/googleLogin" element={<GoogleLoginButton/>} /> */}
        </Routes>
      </BrowserRouter>
      <Socialmedia />
      <Footer />
    </div>
    </HelmetProvider>
  );
}

export default App;
